@if (course) {
  <!-- Alternatively we could wrap everything inside an <ng-container *ngIf="course">  -->
  <div class="course-card" [ngClass]="cardClasses" #container>

    <div class="course-title" [ngStyle]="cardStyles">
      @if (index >= 0 ) {
        {{ index + 1 + '/' + count + ' ' }}
      } {{course.description}}
    </div>

    @if(course.id === 1) {
      <p>Remember course {{course.id}} is necessary to move on!</p>
    } @else if(course.iconUrl && !image) {
      <img [src]="course.iconUrl" alt="Angular Logo" width="300">
    } @else if(!image){
      <h2 [style.text-decoration]="'underline'">No Image Available!</h2>
    }

    <p *ngIf="isForNoobs; else msg"><strong>4 Noobs</strong></p>

    <ng-template #msg>
      <p><strong>Hero on the making</strong></p>
    </ng-template>

    <div class="course-category">
      @switch (course.category) {
        @case("BEGINNER") {
          <div class="category">BEGINNER</div>
        }
        @case("INTERMEDIATE") {
          <div class="category">INTERMEDIATE</div>
        }
        @case("ADVANCED") {
          <div class="category">ADVANCED</div>
        }
        @default {
          <div class="category">UNKNOWN</div>
        }
      }
    </div>

    <!-- Lesson count received trough content projection -->
     <ng-content select=".btn"></ng-content>
     <ng-content select=".course-image"></ng-content>
     @if (contentProjectionToggle === 'h5') {
      <ng-container>
        <h5 class="sub-title">Lessons: {{course.lessonsCount}}</h5>
      </ng-container>
     } @else {
       <ng-container>
         <h6>Enjoy your course</h6>
       </ng-container>
     }


    <!-- Old Syntax
    <ng-container [ngSwitch]="course.category">
      <div class="course-category" >
        <div *ngSwitchCase="'BEGINNER'" class="category">BEGINNER</div>
        <div *ngSwitchCase="'INTERMEDIATE'" class="category">INTERMEDIATE</div>
        <div *ngSwitchCase="'ADVANCED'" class="category">ADVANCED</div>
        <div *ngSwitchDefault class="category">UNKNOWN</div>
      </div>
    </ng-container>
    -->


    <div class="course-description">
      {{ course.longDescription }}
      {{count}}
    </div>

    <button (click)="onCourseViewed()">View Course</button>

  </div>
}
